import React from "react";
import ReactDOM from 'react-dom';
import { SocialIcon } from 'react-social-icons';


function Footer() {
  return (
    <div className="footer">
      <footer class="py-3 bg-dark fixed-bottom">
        <div class="container">
          <p class="m-0 text-center text-white">
            <SocialIcon url="mailto: cz1842@nyu.edu" network="email" fgColor="white" style={{ height: 40, width: 40 }}  />
            <SocialIcon url="https://linkedin.com/in/chengang-zhang" fgColor="white" style={{ height: 40, width: 40 }} />
            <SocialIcon url="https://github.com/chengang-zhang" fgColor="white" style={{ height: 40, width: 40 }} />
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;